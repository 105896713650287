<template>
  <v-container :fluid="true" class="synapsa-container px-0 py-0">
    <v-row class="mb-2 ml-0 mt-0">
      <v-btn
        small
        text
        :color="''"
        class="text-capitalize synapsa-tab log-tab mr-2"
        @mouseenter="feedUrlIconRoute = '/images/icons/green/sidebar/'"
        @mouseleave="
          feedUrlIconRoute = $vuetify.theme.dark
            ? '/images/icons/white/sidebar/'
            : '/images/icons/dark/sidebar/'
        "
        @click="openFeedUrlForm"
      >
        Feed url
        <v-avatar size="10" class="br-0 ml-1">
          <img :src="location + feedUrlIconRoute + 'url-list.svg'" alt="Add" />
        </v-avatar>
      </v-btn>
    </v-row>

    <v-row class="py-0 px-0">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="data"
          :expanded.sync="expanded"
          hide-default-footer
          item-key="id"
          :items-per-page="sizes[size]"
          :show-expand="expandedHeaders.length > 0"
          :loading="loading"
          loading-text="Loading data from API...please wait"
          class="synapsa-table"
        />
      </v-col>
      <v-col
        v-if="data.length > 0"
        cols="6"
        class="py-0 synapsa-pagination mb-5"
      >
        <v-btn-toggle v-model="size" tile group class="results px-1">
          <v-btn x-small class="result" rounded> 5 </v-btn>

          <v-btn x-small class="result" rounded> 10 </v-btn>

          <v-btn x-small class="result" rounded> 25 </v-btn>

          <v-btn x-small class="result" rounded> 50 </v-btn>

          <v-btn x-small class="result" rounded> 100 </v-btn>

          <v-btn x-small class="result" rounded> 200 </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col
        v-if="data.length > 0"
        cols="6"
        class="text-right py-0 synapsa-pagination mb-5"
        :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'"
      >
        <v-pagination
          v-model="page"
          :length="pageTotalCount"
          :total-visible="7"
          circle
          class="d-inline-block pagination px-1 py-1"
          :class="page > 99 ? 'over100' : 'less100'"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import checkPermission from "@/utils/permissions";
export default {
  props: {
    headers: {
      type: Array,
      default: function () {
        return [];
      },
    },
    expandedHeaders: {
      type: Array,
      default: function () {
        return [];
      },
    },
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    tablePage: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tablePageTotalCount: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tableSize: {
      type: Number,
      default: function () {
        return 1;
      },
    },
  },
  data() {
    return {
      expanded: [],

      page: 1,
      pageTotalCount: 1,
      size: 1,

      sizes: [5, 10, 25, 50, 100, 200],

      location: "",
      feedUrlIconRoute: "/images/icons/dark/sidebar/",
    };
  },
  computed: {
    keywordAddForm: {
      get() {
        return this.$store.state.settings.keywordAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "keywordAddForm",
          value: val,
        });
      },
    },
    idAddForm: {
      get() {
        return this.$store.state.settings.idAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "idAddForm",
          value: val,
        });
      },
    },
  },
  watch: {
    tablePage: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.page = newValue;
      },
    },
    tablePageTotalCount: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.pageTotalCount = newValue;
      },
    },
    tableSize: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) this.size = this.sizes.indexOf(newValue);
      },
    },
    page: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue !== this.tablePage) {
          this.$emit("change", { page: newValue, size: this.sizes[this.size] });
        }
      },
    },
    size: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (
          newValue !== undefined &&
          newValue !== this.sizes.indexOf(this.tableSize)
        ) {
          this.$emit("change", { page: this.page, size: this.sizes[newValue] });
        }
      },
    },
    expandedHeaders: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue.length === 0) {
          this.expanded = [];
        }
      },
    },
  },
  methods: {
    checkPermission,
    open(id) {
      console.log(id);
    },
    openEditForm(item) {
      console.log(item);
    },
    openFeedUrlForm() {
      this.keywordAddForm = "feedUrl";
      this.idAddForm = {
        type1: "domainList",
        type2: "predefined",
        type3: this.activeTab === "domainListsPredefined",
        id: this.$route.params.id,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
$synapsaGreen: #69be28;
.log-tab {
  transition-duration: unset;
}
.log-tab.theme--light {
  background: #ffffff6e;
  box-shadow: 13px 13px 30px rgb(0 0 0 / 10%);
}
.log-tab.theme--dark {
  background: #1e1e1e96;
  box-shadow: 13px 13px 30px rgb(0 0 0 / 30%);
}
.log-tab:hover {
  color: $synapsaGreen;
}
</style>
